import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./layouts/Loading";
import Main from "./layouts/Main";
import Controller from "./layouts/Controller";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="App">
      <Router>
        {loading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/controller" element={<Controller />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
