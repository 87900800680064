import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import video1 from "../assets/video1.mp4";
import video2 from "../assets/video2.mp4";
import "../index.css"; // Import your custom styles if needed

function Main() {
  const [bgColor, setBgColor] = useState("#ffffff");
  const [socket, setSocket] = useState(null);
  const [runText, setRunText] = useState("");
  const [vid1, setVideo1] = useState("inactive");
  const [vid2, setVideo2] = useState("inactive");
  const [isStarted, setIsStarted] = useState(false);
  const mainRef = useRef(null);

  useEffect(() => {
    // Show Swal modal on initial page load with custom button color
    Swal.fire({
      title: "Start Experience",
      text: "Click the button to begin the experience.",
      confirmButtonText: "Start",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: 'custom-confirm-button', // Add your custom class here
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setIsStarted(true); // Show the page content
        handleFullScreen();  // Enter full-screen mode
      }
    });
  }, []);

  useEffect(() => {
    if (!isStarted) return; // Wait until the experience is started

    // Establish WebSocket connection
    const ws = new WebSocket("wss://l1x855wh-3000.asse.devtunnels.ms/");
    setSocket(ws);

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    ws.onmessage = (event) => {
      let color = "#ffffff";
      let runningText = "";
      if (event.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result;
          try {
            const parsedData = JSON.parse(text);
            color = parsedData.color || "#ffffff";
            runningText = parsedData.text || "";
            setBgColor(color);
            setRunText(runningText);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        };
        reader.readAsText(event.data);
      } else {
        try {
          const parsedData = JSON.parse(event.data);
          color = parsedData.color || "#ffffff";
          setRunText(parsedData.text);
          setBgColor(color);

          if (parsedData.video === "video1-on") {
            setVideo1("active");
            setVideo2("inactive");
          } else if (parsedData.video === "video2-on") {
            setVideo1("inactive");
            setVideo2("active");
          } else if (parsedData.video === "video1-off") {
            setVideo1("inactive");
          } else if (parsedData.video === "video2-off") {
            setVideo2("inactive");
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close(); // Clean up WebSocket connection
    };
  }, [isStarted]);

  // Trigger full-screen mode
  const handleFullScreen = () => {
    const element = mainRef.current;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { // For Safari
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) { // For Firefox
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) { // For IE/Edge
      element.msRequestFullscreen();
    }
  };

  return (
    <div
      ref={mainRef}
      style={{ backgroundColor: bgColor, minHeight: "100vh" }}
      className={`overflow-hidden ${!isStarted ? "hidden" : ""}`} // Hide content until start
    >
      <video
        className={`absolute inset-0 w-full h-full object-cover z-10 ${
          vid1 === "active" ? "" : "hidden"
        }`}
        autoPlay
        loop
        muted
      >
        <source src={video1} type="video/mp4" />
      </video>
      <video
        className={`absolute inset-0 w-full h-full object-cover z-10 ${
          vid2 === "active" ? "" : "hidden"
        }`}
        autoPlay
        loop
        muted
      >
        <source src={video2} type="video/mp4" />
      </video>
      <div className="relative flex items-center text-outline-blue justify-center h-screen">
        <div className="absolute text-[380px] font-bold animate-marquee whitespace-nowrap uppercase">
          {runText}
        </div>
      </div>
    </div>
  );
}

export default Main;
