import React, { useState, useEffect } from "react";

function Controller() {
  const [bgColor, setBgColor] = useState("#ffffff");
  const [text, setText] = useState("This is a big running text!");

  const [socket, setSocket] = useState(null);
  const [isVideo1Active, setIsVideo1Active] = useState(false);
  const [isVideo2Active, setIsVideo2Active] = useState(false);

  useEffect(() => {
    const ws = new WebSocket("wss://l1x855wh-3000.asse.devtunnels.ms/");
    setSocket(ws);

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    return () => {
      ws.close();
    };
  }, []);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setBgColor(newColor);

    console.log(text);

    const message = JSON.stringify({
      color: newColor,
      text: text,
    });

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  };

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);

    const message = JSON.stringify({
      color: bgColor,
      text: newText,
    });

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    }
  };

  const handleVideo1 = () => {
    setIsVideo1Active((prevState) => {
      const newState = !prevState;

      // Determine the message based on the new state
      const message = JSON.stringify({
        color: null,
        text: null,
        video: newState ? "video1-on" : "video1-off", // Send different values for active/inactive
      });

      // Check if WebSocket is open and send the message
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      }

      return newState; // Update the state based on the new toggle value
    });
  };

  const handleVideo2 = () => {
    // Toggle the state first
    setIsVideo2Active((prevState) => {
      const newState = !prevState;

      // Determine the message based on the new state
      const message = JSON.stringify({
        color: null,
        text: null,
        video: newState ? "video2-on" : "video2-off", // Send different values for active/inactive
      });

      // Check if WebSocket is open and send the message
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      }

      return newState; // Update the state based on the new toggle value
    });
  };

  return (
    <div
      style={{ backgroundColor: bgColor, minHeight: "100vh" }}
      className="flex flex-col items-center justify-center"
    >
      {/* Color Picker Input */}
      <div className="flex flex-col items-center space-y-2">
        <label htmlFor="color-picker" className="text-[50px] font-semibold">
          Color Controller:
        </label>
        <input
          id="color-picker"
          type="color"
          value={bgColor}
          onChange={handleColorChange}
          className="w-16 h-16 p-1 border-4 border-gray-300 rounded-full shadow-lg transition duration-300 ease-in-out hover:shadow-2xl hover:border-gray-500"
        />

        {/* Text Input */}
        <label htmlFor="text-input" className="text-[50px] font-semibold mt-4">
          Text Controller:
        </label>
        <input
          id="text-input"
          type="text"
          value={text}
          onChange={handleTextChange}
          className="p-2 border-4 border-gray-300 rounded-lg shadow-lg transition duration-300 ease-in-out hover:shadow-2xl hover:border-gray-500"
        />
      </div>
      <div className="flex flex-row gap-10 mt-10">
        <button
          onClick={handleVideo1}
          className={`py-5 px-10 rounded-lg ${
            isVideo1Active ? "bg-green-500" : "bg-blue-500"
          }`}
        >
          {isVideo1Active ? "Video 1 ON" : "Video 1 OFF"}
        </button>
        <button
          onClick={handleVideo2}
          className={`py-5 px-10 rounded-lg ${
            isVideo2Active ? "bg-green-500" : "bg-blue-500"
          }`}
        >
          {isVideo2Active ? "Video 2 ON" : "Video 2 OFF"}
        </button>
      </div>
    </div>
  );
}

export default Controller;
